import React from 'react';
import useRequest from '../../Hooks/useRequest';
import { UnsubscribePhoto as UnsubscribePhotoFromAPI } from '../../Helper/api';
import { useLocation } from 'react-router-dom';
import ContentOverlayLoader from '../../Components/ContentOverlayLoader';
import Status from '../../Constants/Status';
import { useIntl } from 'react-intl';

const UnsubscribePhoto = () => {
  const { formatMessage } = useIntl();

  const { search } = useLocation();

  const { status } = useRequest(() => UnsubscribePhotoFromAPI(search), []);

  return (
    <div className="w-100">
      <ContentOverlayLoader
        status={status}
        error={formatMessage({ id: 'Unsubscribe.error' })}
      >
        {status === Status.Fullfilled ? (
          <p>{formatMessage({ id: 'Unsubscribe.success' })}</p>
        ) : (
          <></>
        )}
      </ContentOverlayLoader>
    </div>
  );
};

export default UnsubscribePhoto;
